:root {
  --send-bg: #0b93f6;
  --send-color: white;
  --receive-bg: #e5e5ea;
  --receive-text: black;
  --page-background: #24243a;
}
@font-face {
  font-family: Recolata-Bold;
  src: url(./fonts/Recoleta-Bold.woff);
}
@font-face {
  font-family: Recolata-SemiBold;
  src: url(./fonts/Recoleta-SemiBold.woff);
}
@font-face {
  font-family: Recolata-Regular;
  src: url(./fonts/Recoleta-Regular.woff);
}

@font-face {
  font-family: Recolata-Light;
  src: url(./fonts/Recoleta-Light.woff);
}
@font-face {
  font-family: Recolata-Medium;
  src: url(./fonts/Recoleta-Medium.woff);
}
.blobRight {
  position: absolute;
  width: 1436.37px;
  height: 80vh;
  left: 979.68px;
  /* top: -349px; */
  background: linear-gradient(135deg, #172368 2.88%, #cd6549 100%);
  filter: blur(200px);
  transform: rotate(-64.46deg);
}
.blobLeft {
  position: absolute;
  width: 1436.37px;
  height: 500.13px;
  left: -900px;
  top: 609px;
  background: linear-gradient(135deg, #172368 2.88%, #cd6549 100%);
  filter: blur(200px);
  transform: rotate(130.41deg);
}
.App {
  background-color: var(--page-background);
  width: 100vw;
  overflow: hidden;
}
.Chat {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 20px;
  font-weight: normal;
  max-width: 450px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  background-color: var(--page-background);
}

.Chat p {
  max-width: 255px;
  word-wrap: break-word;
  margin-bottom: 12px;
  line-height: 24px;
  position: relative;
  padding: 10px 20px;
  border-radius: 25px;
}

.Chat p:before,
.Chat p:after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 25px;
}

.send {
  color: var(--send-color);
  background: var(--send-bg);
  align-self: flex-end;
}
.gif {
  background: var(--receive-bg);
  color: black;
  align-self: flex-start;
  background: var(--page-background);
}

.send:before {
  right: -7px;
  width: 20px;
  background-color: var(--send-bg);
  border-bottom-left-radius: 16px 14px;
}

.send:after {
  right: -26px;
  width: 26px;
  background-color: var(--page-background);
  border-bottom-left-radius: 10px;
}
.receive {
  background: var(--receive-bg);
  color: black;
  align-self: flex-start;
}
.receive:before {
  left: -7px;
  width: 20px;
  background-color: var(--receive-bg);
  border-bottom-right-radius: 16px 14px;
}
.receive:after {
  left: -26px;
  width: 26px;
  background-color: var(--page-background);
  border-bottom-right-radius: 10px;
}
.Chat img {
  border-radius: 10px;
}

.searchBar {
  margin: 100px auto;
  min-width: 200px;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  border: solid 1.3px rgb(180, 180, 180);
  border-radius: 20px;
  padding: 2px;
  padding-left: 20px;
  background-color: white;
}

.searchBar input {
  height: 100%;
  width: 85%;
  border: none;
  background-color: white;
}
.searchBar input:focus {
  outline: none;
  background-color: white;
}
.searchBar input:active {
  outline: none;
  background-color: white;
}
.srch-Btn {
  background: black;
  color: white;
  border-radius: 0px 20px 20px 0px;
  padding: 10px 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.srch-Btn > p {
  font-size: 15px;
  margin-bottom: 10px;
}

.searchBar p {
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 600px;
  line-height: 25px;
}
input:focus {
  outline: none;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 20px auto;
}
.headerButton {
  color: #fafafa;
  background: linear-gradient(
    90deg,
    hsla(339, 100%, 55%, 1) 0%,
    hsla(197, 100%, 64%, 1) 100%
  );
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 20;
}
.headerButton > p {
  color: #fafafa;
  background: rgb(117, 74, 88);
  height: 20px;
  padding: 10px 30px;
  border-radius: 5px;
}
.form-control2 {
  position: relative;
  z-index: 20;
  background: #24243a;
  color: white;

  height: 50px;
  width: 300px;
  border: 2px solid #24243a;
  border-right: none;
}
#data-center {
  border-radius: 43px;
}
.asset {
  position: absolute;
  width: 90px;
  z-index: 20;
}
.asset1 {
  top: 70%;
  left: 20%;
  animation-name: floating1;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.asset2 {
  top: 88%;
  left: 13%;
  animation-name: floating2;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.asset3 {
  top: 100%;
  left: 23%;
  animation-name: floating3;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.asset4 {
  top: 120%;
  left: 17%;
  animation-name: floating4;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.asset5 {
  top: 140%;
  left: 28%;
  animation-name: floating5;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.asset6 {
  top: 70%;
  right: 17%;
  animation-name: floating6;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.asset7 {
  top: 88%;
  right: 13%;
  animation-name: floating7;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.asset8 {
  top: 100%;
  right: 20%;
  animation-name: floating8;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.asset9 {
  top: 120%;
  right: 13%;
  animation-name: floating9;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.asset10 {
  top: 140%;
  right: 20%;
  animation-name: floating10;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 80%;
  margin: 0 auto;
  margin-top: 250px;
}
.gridItem {
  z-index: 20;
  padding: 20px;
  font-family: Recolata-Regular;
  background: #4c2f3b;
  border-radius: 12px;
  width: 170px;
  text-align: center;
  color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
footer {
  margin: 0 auto;
  margin-top: 100px;
  border-top: 1px solid grey;
  width: 85%;
  padding: 100px 20px;
  display: flex;
  justify-content: space-between;
  color: #bdbdbd;
}
a {
  color: #bdbdbd;
}
footer img {
  margin-right: 10px;
  cursor: pointer;
}
@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
@keyframes floating1 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
@keyframes floating2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
@keyframes floating3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 12px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
@keyframes floating4 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 19px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
@keyframes floating5 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 6px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
@keyframes floating6 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
@keyframes floating7 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 13px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
@keyframes floating8 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 18px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
@keyframes floating9 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 12px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
@keyframes floating10 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 8px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
@media only screen and (max-width: 1300px) {
  .asset5 {
    top: 140%;
    right: 28%;
  }
}
@media only screen and (max-width: 1200px) {
  .blobLeft {
    display: none;
  }
  .asset {
    display: none;
  }
}
@media only screen and (max-width: 1100px) {
  .grid {
    grid-template-columns: 1fr 1fr;
    width: 90%;
    margin-top: 100px;
  }
  .gridItem {
    width: 150px;
    font-size: 12px;
    padding: 10px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}
.notice {
  text-align: center;
  width: clamp(300px, 50%, 520px);
  margin-bottom: 30px;
  margin-top: -80px;
  font-size: 12px;
  color: #8b8b8b;
}
.iphone-x {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 40px auto;
  width: 360px;
  height: 780px;
  background-color: #7371ee;
  background-image: linear-gradient(60deg, #7371ee 1%, #a1d9d6 100%);
  border-radius: 40px;
  box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919,
    0px 0px 0px 20px #111;
  z-index: 20;
}
.iphone-x::-webkit-scrollbar {
  display: none;
}
.iphone-x:before,
.iphone-x:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.iphone-x:after {
  bottom: 7px;
  width: 140px;
  height: 4px;
  background-color: #f2f2f2;
  border-radius: 10px;
}
.iphone-x:before {
  top: 0px;
  width: 56%;
  height: 30px;
  background-color: #1f1f1f;
  border-radius: 0px 0px 40px 40px;
}
.iphone-x i,
.iphone-x b,
.iphone-x s,
.iphone-x span {
  position: absolute;
  display: block;
  color: transparent;
}
.iphone-x i {
  top: 0px;
  left: 50%;
  transform: translate(-50%, 6px);
  height: 8px;
  width: 15%;
  background-color: #101010;
  border-radius: 8px;
  box-shadow: inset 0px -3px 3px 0px rgba(255, 255, 255, 0.2);
}
.iphone-x b {
  left: 10%;
  top: 0px;
  transform: translate(180px, 4px);
  width: 12px;
  height: 12px;
  background-color: #101010;
  border-radius: 12px;
  box-shadow: inset 0px -3px 2px 0px rgba(255, 255, 255, 0.2);
}
.iphone-x b:after {
  content: "";
  position: absolute;
  background-color: #2d4d76;
  width: 6px;
  height: 6px;
  top: 2px;
  left: 2px;
  top: 3px;
  left: 3px;
  display: block;
  border-radius: 4px;
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
}
.iphone-x s {
  top: 50px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  width: 100%;
  font-size: 70px;
  font-weight: 100;
  padding-top: 60px;
}
.iphone-x span {
  bottom: 50px;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  left: 30px;
}
.iphone-x span + span {
  left: auto;
  right: 30px;
}

@media only screen and (max-width: 900px) {
  footer {
    flex-direction: column;
    align-items: center;
  }
  footer img {
    margin-bottom: 30px;
  }
  .contact {
    margin-top: 30px;
  }
  .notice {
    margin-top: -30px;
    width: 350px;
  }
  .notice {
    font-size: 9px;
  }
  .iphone-x {
    width: 320px;
    height: 740px;
  }
}
